<template>
  <a-table
    class="gx-table-responsive"
    :columns="columns"
    :data-source="clubs"
    :loading="teamsLoading"
  >
    <div slot="status" slot-scope="text, row" class="gx-text-left">
      <a-tag
        v-if="row.coachStatus === 'accept'"
        color="#27ae60"
        style="margin-bottom: 0px; border-radius: 30px"
        >Active</a-tag
      >
      <a-tag
        v-if="row.coachStatus === 'invite'"
        color="#f39c12"
        style="margin-bottom: 0px; border-radius: 30px"
        >Invite Pending</a-tag
      >
      <!-- <a-tag
          v-if="text === 'request'"
          color="#8e44ad"
          style="margin-bottom: 0px; border-radius: 30px"
          >Has Requested to Join</a-tag
        > -->
      <a-tag
        v-if="row.coachStatus === 'cancel'"
        color="#8e44ad"
        style="margin-bottom: 0px; border-radius: 30px"
        >No Longer a Member</a-tag
      >

      <a-tag
        v-if="row.coachStatus === 'decline'"
        color="#c0392b"
        style="margin-bottom: 0px; border-radius: 30px"
        >Declined Invitation</a-tag
      >
    </div>
    <div slot="invitation" slot-scope="text, row" class="gx-text-left">
      <a-button
        v-if="row.coachStatus === 'invite'"
        size="small"
        style="
          color: #f5222d;
          background-color: #f5f5f5;
          border-color: #d9d9d9;
          margin-bottom: 0px;
        "
        type="danger"
        @click="actionInvitation(row.id, row.inviteId, 'accept')"
        >Accept
      </a-button>
      <a-button
        v-if="row.coachStatus === 'invite'"
        size="small"
        style="
          color: #f5222d;
          background-color: #f5f5f5;
          border-color: #d9d9d9;
          margin-right: 5px;
          margin-bottom: 0px;
        "
        type="danger"
        @click="actionInvitation(row.id, row.inviteId, 'decline')"
        >Decline</a-button
      >
    </div>
    <div slot="operations" slot-scope="text, row" class="gx-text-left">
      <router-link :to="'/club/' + row.id">
        <a-button
          type="primary"
          size="small"
          style="margin-left: 5px; margin-bottom: 0"
        >
          View
        </a-button>
      </router-link>
    </div>
  </a-table>
</template>

<script>
import timeMixing from "@/mixins/time";
import nCurrency from "@/mixins/currency";
import { clubService } from "@/common/api/api.service";
import { memberService } from "../../common/api/api.service";

const columns = [
  {
    title: "Club Name",
    dataIndex: "club_name",
    key: "club_name",
  },
  {
    title: "Club Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Contact Number",
    dataIndex: "contact_number",
    key: "contact_number",
  },
  {
    title: "Invitation status",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "Invitation action",
    scopedSlots: {
      customRender: "invitation",
    },
  },
  {
    title: "Actions",
    scopedSlots: {
      customRender: "operations",
    },
  },
];

export default {
  name: "ClubTeamsTable",
  mixins: [nCurrency, timeMixing],
  data() {
    return {
      columns,
      teams: [],
      clubs: [],
      teamsLoading: true,
    };
  },
  mounted() {
    this.getClubs();
  },
  methods: {
    getClubs() {
      clubService.getMyAllClub().then((resp) => {
        if (resp.data.success) {
          this.clubs = resp.data.result;
          // alert(JSON.stringify(this.teams));
        }
        this.teamsLoading = false;
      });
    },
    actionInvitation(memberId, inviteId, status) {
      this.loader = true;
      memberService
        .CoachInvitation(memberId, {
          status: status,
          inviteId: inviteId,
          coach: true,
        })
        .then((resp) => {
          this.loader = false;
          if (resp.data.success) {
            this.getClubs();
          }
        });
    },
  },
};
</script>

<style scoped></style>
