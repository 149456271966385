var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.clubs,
      loading: _vm.teamsLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-left" },
            [
              row.coachStatus === "accept"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#27ae60" },
                    },
                    [_vm._v("Active")]
                  )
                : _vm._e(),
              row.coachStatus === "invite"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#f39c12" },
                    },
                    [_vm._v("Invite Pending")]
                  )
                : _vm._e(),
              row.coachStatus === "cancel"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#8e44ad" },
                    },
                    [_vm._v("No Longer a Member")]
                  )
                : _vm._e(),
              row.coachStatus === "decline"
                ? _c(
                    "a-tag",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "border-radius": "30px",
                      },
                      attrs: { color: "#c0392b" },
                    },
                    [_vm._v("Declined Invitation")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
      {
        key: "invitation",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-left" },
            [
              row.coachStatus === "invite"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        color: "#f5222d",
                        "background-color": "#f5f5f5",
                        "border-color": "#d9d9d9",
                        "margin-bottom": "0px",
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.actionInvitation(
                            row.id,
                            row.inviteId,
                            "accept"
                          )
                        },
                      },
                    },
                    [_vm._v("Accept\n    ")]
                  )
                : _vm._e(),
              row.coachStatus === "invite"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        color: "#f5222d",
                        "background-color": "#f5f5f5",
                        "border-color": "#d9d9d9",
                        "margin-right": "5px",
                        "margin-bottom": "0px",
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.actionInvitation(
                            row.id,
                            row.inviteId,
                            "decline"
                          )
                        },
                      },
                    },
                    [_vm._v("Decline")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
      {
        key: "operations",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-left" },
            [
              _c(
                "router-link",
                { attrs: { to: "/club/" + row.id } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-left": "5px",
                        "margin-bottom": "0",
                      },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("\n        View\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }